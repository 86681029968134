import React from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";

const Buttons = ({ style, hasCancel, cancel, submit, hasSubmit = true, isDisabled = false }) => {
  return (
    <React.Fragment>
      <Grid container spacing={2} justifyContent="center">
        <Grid item {...style.cancel.props}>
          {hasCancel ? (
            <Button
              data-testid="btn-cancel"
              onClick={cancel}
              style={{
                fontFamily: "Readex Pro",
                fontWeight: "600",
                fontSize: "14px",
                color: "#363853",
              }}
            >
              {style.cancel.name}
            </Button>
          ) : (
            ""
          )}
        </Grid>
        <Grid item {...style.submit.props}>
          {hasSubmit ? (
            <Button
              disabled={isDisabled}
              onClick={submit}
              variant="contained"
              data-testid="btn-submit"
              style={{
                fontFamily: "Readex Pro",
                backgroundColor: isDisabled ? "#E0E0E0" : "#363853",
                borderRadius: "16px",
                color: "#FFFFFF",
                height: "48px",
              }}
            >
              {style.submit.name}
            </Button>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Buttons;
