import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Grid, Typography } from "@mui/material";
import Avatar from "@mui/material/Avatar";

import defaultUser from "../assets/logoTigo/default-user.jpg";

import Buttons from "./Buttons";

import { currencyFormatter } from "../utils/numeric-store";

const SubTitlesPB = ({ text }) => {
  return (
    <Typography
      style={{
        letterSpacing: "0.247px",
        color: "#4C5866",
        fontFamily: "Readex Pro",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "22px",
      }}
    >
      {text}
    </Typography>
  );
};
const TitlesPB = ({ text }) => {
  return (
    <Typography
      style={{
        textAlign: "center",
        padding: "30px 0px 20px 0px",
        fontFamily: "Readex Pro",
        fontSize: "28px",
        fontWeight: "600",
        lineHeight: "34px",
        letterSpacing: "0px",
        color: "#343C46",
      }}
    >
      {text}
    </Typography>
  );
};
const AmountPB = ({ amount, currency }) => {
  return (
    <div
      style={{
        fontFamily: "Readex Pro",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "30px",
        lineHeight: "34px",
        color: "#000000",
      }}
    >
      {currencyFormatter(amount, currency)}
    </div>
  );
};
const ConfirmPayment = (props) => {
  const [t] = useTranslation("global");
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Box
          style={{
            paddingTop: "35px",
            width: "100%",
            fontFamily: "Readex Pro",
          }}
        >
          <Grid item xs={12} style={{ marginTop: "15px", marginBottom: "15px" }}>
            <TitlesPB text={t("ConfirmPaymentButton.Title")} />
          </Grid>
          <Grid item xs={12} style={{ margin: "25px" }}>
            <Grid container>
              <Grid item xs={12} style={{ marginBottom: "15px" }}>
                <SubTitlesPB text={t("ConfirmPaymentButton.SubTitle")} />
              </Grid>
              <Grid item xs={2} style={{ marginBottom: "15px" }}>
                <Avatar alt="Remy Sharp" src={defaultUser} sx={{ width: 60, height: 60 }} />
              </Grid>
              <Grid
                item
                xs={8}
                ml={2}
                style={{
                  fontFamily: "Readex Pro",
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "20px",
                  letterSpacing: "0.247px",
                  color: "#000000",
                }}
              >
                {props.app_name}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ margin: "25px" }}>
            <Grid container>
              <Grid item xs={12}>
                <SubTitlesPB text={t("ConfirmPaymentButton.Amount")} />
              </Grid>
              <Grid item xs={12} style={{ marginTop: "10px" }}>
                <AmountPB amount={props.amount} currency={props.currency} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction="row"
            style={{
              marginTop: "25%",
              marginLeft: "10px",
            }}
          >
            <Buttons
              cancel={() => {}}
              submit={() => {
                props.payment(props.data, {
                  success: props.url_success,
                  error: props.url_error,
                });
              }}
              style={{
                cancel: {
                  name: t("ConfirmPaymentButton.Cancel"),
                  props: { xs: 6 },
                },
                submit: {
                  name: t("ConfirmPaymentButton.Accept"),
                  props: { xs: 12 },
                },
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </React.Fragment>
  );
};

export default ConfirmPayment;
