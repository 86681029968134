import { Route, Routes } from "react-router-dom";

import "./App.css";

import PaymentButtonProcess from "./views/PaymentButtonProcess";

function App() {
  return (
    <Routes>
      <Route path="/" element={<PaymentButtonProcess />} />
    </Routes>
  );
}

export default App;
