import { Fragment, useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useTranslation } from "react-i18next";

import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button, LinearProgress } from "@mui/material";
import { Box } from "@mui/system";
import { MuiOtpInput } from "mui-one-time-password-input";

import Buttons from "./Buttons";

const OtpCognito = (props) => {
  const TIMER_MILLISECONDS = 300000;
  const MINUTES_MILLISECONDS = 60000;
  const SECONDS_MILLISECONDS = 1000;
  const PRECISION_MILLISECONDS = 4;
  const PERCENT = 100;
  const OTP_LENGTH = 6;

  const [t] = useTranslation("global");
  const [otp, setOtp] = useState("");
  const [showError, setShowError] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [key, setKey] = useState("");
  const [data, setData] = useState({
    date: Date.now(),
    delay: TIMER_MILLISECONDS,
  });

  useEffect(() => {
    const savedDate = localStorage.getItem("timer");

    if (savedDate !== null && !isNaN(savedDate)) {
      const currentTime = Date.now();
      const delta = parseInt(savedDate, 10) - currentTime;

      if (delta > TIMER_MILLISECONDS) {
        if (localStorage.getItem("timer").length > 0) localStorage.removeItem("timer");
      } else setData({ date: currentTime, delay: delta });
    }
  }, []);

  const handleChangeOtp = (newValue) => {
    setOtp(newValue);
    if (showError) setShowError(false);
  };

  function matchIsNumeric(text) {
    const isNumber = typeof text === "number";
    const isString = typeof text === "string";
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  }

  const validateChar = (value, _) => {
    return matchIsNumeric(value);
  };

  const resendCode = async (event) => {
    event.preventDefault();

    if (showError) setShowError(false);
    setOtp("");

    const isOk = await props.resendCode(props.data);

    if (isOk) {
      const currentTime = Date.now();
      setKey(currentTime);
      setDisabled(false);
      setData({ date: currentTime, delay: TIMER_MILLISECONDS });

      localStorage.removeItem("timer");
    }
  };

  const validateOtp = (event) => {
    event.preventDefault();
    if (otp.length < OTP_LENGTH) {
      setShowError(true);
      return;
    }

    props.ValidateOTP(otp, props.data);
  };

  const renderer = (props) => {
    if (!props.completed) {
      const percent =
        PERCENT -
        ((props.minutes * MINUTES_MILLISECONDS + props.seconds * SECONDS_MILLISECONDS) * PERCENT) / TIMER_MILLISECONDS;
      return (
        <Fragment>
          <span>
            {t("MfaScreen.RemainingTime", {
              timer: props.formatted.minutes + ":" + props.formatted.seconds,
            })}
          </span>
          <LinearProgress
            variant="determinate"
            value={percent}
            sx={{
              backgroundColor: "#FABE00",
              borderRadius: 16,
              marginTop: "8px",
            }}
          />
        </Fragment>
      );
    }
  };

  return (
    <Box style={{ textAlign: "center", fontFamily: "Readex Pro" }}>
      <h2
        style={{
          fontFamily: "Readex Pro",
          fontSize: "28px",
          fontWeight: "600",
          lineHeight: "34px",
          letterSpacing: "0px",
          color: "#343C46",
          padding: "30px 0px 20px 0px",
        }}
      >
        {t("MfaScreen.Title")}
      </h2>
      <Box style={{ marginTop: "10px", textAlign: "center " }} mb={2}>
        <Countdown
          date={data.date + data.delay}
          renderer={renderer}
          key={key}
          intervalDelay={SECONDS_MILLISECONDS}
          precision={PRECISION_MILLISECONDS}
          onStart={(_) => {
            if (localStorage.getItem("timer") === null)
              localStorage.setItem("timer", JSON.stringify(data.date + data.delay));
          }}
          onComplete={() => {
            if (localStorage.getItem("timer") !== null) {
              localStorage.removeItem("timer");
              setDisabled(true);
            }
          }}
        />
      </Box>
      <Box
        style={{
          padding: "32px 20px",
          boxShadow: "0px 4px 12px rgba(194, 209, 217, 0.46)",
          borderRadius: "24px",
        }}
      >
        <Box display="flex" justifyContent="center">
          <MuiOtpInput
            length={OTP_LENGTH}
            value={otp}
            onChange={handleChangeOtp}
            className="MuiOtp"
            validateChar={validateChar}
            TextFieldsProps={{
              placeholder: "-",
              sx: {
                input: {
                  fontFamily: "Readex Pro",
                  fontWeight: 400,
                  fontSize: 36,
                  color: "#404A56",
                },
              },
            }}
          />
        </Box>
        {showError && (
          <Box
            style={{
              background: "#FAEDEA",
              border: "1px solid #FAEDEA",
              borderRadius: "8px",
              padding: "16px 8px",
            }}
            mt={4}
          >
            <Box style={{ color: "#D95A3A", top: "10.42%", float: "left" }}>
              <WarningAmberIcon />
            </Box>
            <Box>{t("MfaScreen.IncorrectCode")}</Box>
          </Box>
        )}
        <Box style={{ textAlign: "center", marginTop: "40px" }}>
          {t("MfaScreen.Question")}
          <Button
            sx={{
              fontFamily: "Readex Pro",
              textDecoration: "none",
              fontSize: "16px",
              fontWeight: "600",
              color: "#404A56",
            }}
            data-testid="btn-resend"
            onClick={async (e) => await resendCode(e)}
          >
            {t("MfaScreen.Resend")}
          </Button>
        </Box>
      </Box>
      <Box style={{ margin: "60px 0px 20px 0px" }}>
        <Buttons
          data-testid="btn-submit"
          isDisabled={disabled}
          cancel={() => {}}
          submit={(e) => {
            validateOtp(e);
          }}
          style={{
            cancel: {
              name: "",
              props: { xs: 6 },
            },
            submit: {
              name: t("MfaScreen.Enter"),
              props: { xs: 12 },
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default OtpCognito;
