import ConfirmPayment from "./ConfirmPayment";
import FormSignIn from "./FormSingIn";
import MessageScreen from "./MessageScreen";
import OtpCognito from "./otpComponent";

const inputsComponents = {
  confirmPayment: ConfirmPayment,
  formSignIn: FormSignIn,
  otpCognito: OtpCognito,
  loading: MessageScreen,
};

const Input = ({ type, data }) => {
  const InputsComponent = inputsComponents[[type]];

  if (!InputsComponent) {
    return null;
  }

  return <InputsComponent {...data} />;
};

const GenericComponent = (props) => {
  return <Input type={props.type} data={props} />;
};

export default GenericComponent;
