import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import i18next from "i18next";

import "./index.css";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Theme from "./themes/Theme";
import countries from "./translations/country.json";
import global_en from "./translations/en/global.json";
import global_es from "./translations/es/global.json";
import global_bo from "./translations/bo/global.json";
import global_gt from "./translations/gt/global.json";
import global_hn from "./translations/hn/global.json";
import global_pa from "./translations/pa/global.json";
import global_py from "./translations/py/global.json";
import global_sv from "./translations/sv/global.json";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const result = window.location.origin;
const div = result.split(".");
let country = "es";
for (const x in div) {
  for (const y in Object.keys(countries)) {
    if (div[x] === Object.keys(countries)[y]) {
      country = div[x];
    }
  }
}

i18next.init({
  lng: country,
  resources: {
    hn: {
      global: global_hn,
    },
    py: {
      global: global_py,
    },
    bo: {
      global: global_bo,
    },
    sv: {
      global: global_sv,
    },
    pa: {
      global: global_pa,
    },
    gt: {
      global: global_gt,
    },
    es: {
      global: global_es,
    },
    en: {
      global: global_en,
    },
  },
});

root.render(
  <StrictMode>
    <I18nextProvider i18n={i18next}>
      <BrowserRouter>
        <ThemeProvider theme={Theme}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
    </I18nextProvider>
  </StrictMode>
);

reportWebVitals();
